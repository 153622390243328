import React, { useState, useEffect, useRef } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import GameOverModal from './components/GameOverModal';
import GameHeader from './components/GameHeader';
import { API_URLS } from '../../../config/config';
import './styles/hangiHarfiGordun.css'; // Stil dosyası
import axiosInstance from '../../../utils/axiosInstance'; 
import axios from 'axios'; 

function HangiHarfiGordun() {
  const navigate = useNavigate();
  const location = useLocation();
  const { stage, exercise } = location.state || {};
  const [difficulty, setDifficulty] = useState('kolay');
  const [letters, setLetters] = useState([]);
  const [input, setInput] = useState('');
  const [score, setScore] = useState(0);
  const [gameActive, setGameActive] = useState(false);
  const [timeLeft, setTimeLeft] = useState(15);
  const [showLetters, setShowLetters] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [showIntroduction, setShowIntroduction] = useState(true);
  const [attempts, setAttempts] = useState([]);
  const [speedFactor, setSpeedFactor] = useState(1);
  const baseDisplayDuration = 1000; 
  const canvasRef = useRef(null);
  const inputRef = useRef(null);
  const [userData, setUserData] = useState(null);
  const [userRole, setUserRole] = useState(null);
  const [totalTime, setTotalTime] = useState(15);

  // ─────────────────────────────────────────────────────────────────
  //  FETCH USER + SET TIME
  // ─────────────────────────────────────────────────────────────────
  useEffect(() => {
    const fetchUserData = async () => {
      try {
        // Öğrenci bilgisi
        const studentResponse = await axiosInstance.get(`${API_URLS.STUDENT_URL}/me`);
        if (
          studentResponse.status === 200 &&
          studentResponse.data &&
          studentResponse.data.status === 'active'
        ) {
          const studentData = studentResponse.data;
          setUserData(studentData);
          setUserRole('student');
          return;
        }
      } catch (error) {
        console.error('Öğrenci verisi alınırken hata oluştu:', error);
      }

      // Öğrenci yoksa öğretmen bilgisi
      try {
        const token = localStorage.getItem('userToken');
        const teacherResponse = await fetch(`${API_URLS.TEACHER_URL}/me`, {
          method: 'GET',
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        });
        if (teacherResponse.ok) {
          const teacherData = await teacherResponse.json();
          setUserData(teacherData);
          setUserRole('teacher');
          return;
        }
      } catch (error) {
        console.error('Öğretmen verisi alınırken hata oluştu:', error);
      }

      // Hiçbiri değilse
      setUserData(null);
      setUserRole(null);
    };

    fetchUserData();
  }, []);

  // Zamanı userData'ya göre ayarla
  useEffect(() => {
    if (userData && userData.status === 'active') {
      setTotalTime(40);
      setTimeLeft(40);
    } else {
      setTotalTime(15);
      setTimeLeft(15);
    }
  }, [userData]);

  // ─────────────────────────────────────────────────────────────────
  //  MAIN TIMER
  // ─────────────────────────────────────────────────────────────────
  useEffect(() => {
    if (gameActive && timeLeft > 0) {
      const timer = setTimeout(() => setTimeLeft((prev) => prev - 1), 1000);
      return () => clearTimeout(timer);
    } else if (timeLeft === 0) {
      onGameOver();
    }
  }, [timeLeft, gameActive]);

  // ─────────────────────────────────────────────────────────────────
  //  CANVAS DRAW TRIGGER
  // ─────────────────────────────────────────────────────────────────
  useEffect(() => {
    if (gameActive && showLetters) {
      drawCanvas();
    } else {
      clearCanvas();
    }
  }, [gameActive, showLetters, letters]);

  // ─────────────────────────────────────────────────────────────────
  //  WHEN GAME STARTS, GENERATE LETTERS
  // ─────────────────────────────────────────────────────────────────
  useEffect(() => {
    if (gameActive) {
      generateLetters();
    }
  }, [gameActive]);

  // ─────────────────────────────────────────────────────────────────
  //  FOCUS INPUT AFTER LETTERS HIDE
  // ─────────────────────────────────────────────────────────────────
  useEffect(() => {
    if (gameActive && !showLetters && inputRef.current) {
      inputRef.current.focus();
    }
  }, [gameActive, showLetters]);

  // ─────────────────────────────────────────────────────────────────
  //  INTRO SCREEN -> ENTER GAME
  // ─────────────────────────────────────────────────────────────────
  const enterGame = () => {
    setShowIntroduction(false);
  };

  // ─────────────────────────────────────────────────────────────────
  //  START / STOP / RESET
  // ─────────────────────────────────────────────────────────────────
  const startGame = () => {
    setScore(0);
    setTimeLeft(totalTime);
    setGameActive(true);
    setAttempts([]);
  };

  const stopGame = () => {
    setGameActive(false);
  };

  const resetGame = () => {
    clearCanvas();
    setGameActive(false);
    setScore(0);
    setTimeLeft(totalTime);
    setLetters([]);
    setInput('');
    setShowLetters(false);
    setShowModal(false);
    setShowIntroduction(false);
    setAttempts([]);
  };

  // ─────────────────────────────────────────────────────────────────
  //  GAME OVER
  // ─────────────────────────────────────────────────────────────────
  const onGameOver = () => {
    setGameActive(false);
    setShowModal(true);
    updateProgress();
  };

  // ─────────────────────────────────────────────────────────────────
  //  GENERATE LETTERS
  // ─────────────────────────────────────────────────────────────────
  const generateLetters = () => {
    if (!canvasRef.current) return;

    // Belirleyeceğimiz harf sayısı
    let letterCount;
    switch (difficulty) {
      case 'kolay':
        letterCount = 2;
        break;
      case 'orta':
        letterCount = 3;
        break;
      case 'zor':
        letterCount = Math.floor(Math.random() * 2) + 4;
        break;
      default:
        letterCount = 2;
    }

    const canvas = canvasRef.current;
    const centerX = canvas.width / 2;
    const centerY = canvas.height / 2;

    const maxRadius = canvas.width / 2 - 50;
    const minRadius = 50;
    const elapsed = totalTime - timeLeft;
    const baseRadius = minRadius + ((maxRadius - minRadius) * elapsed) / totalTime;

    const lettersArray = [];
    const positions = [];

    // Rastgele sağ / sol yerleşimler
    let directions = [];
    if (letterCount === 2) {
      directions = [1, -1];
    } else {
      directions = [1, -1];
      for (let i = 2; i < letterCount; i++) {
        directions.push(Math.random() < 0.5 ? -1 : 1);
      }
      directions = shuffleArray(directions);
    }

    for (let i = 0; i < letterCount; i++) {
      let direction = directions[i];
      const radius = baseRadius + i * 30;
      const x = centerX + direction * radius;
      const y = centerY;

      // Yeni harf, önce overlap kontrolü
      const overlap = positions.some((pos) => {
        const dx = pos.x - x;
        return Math.abs(dx) < 30; 
      });

      if (!overlap) {
        positions.push({ x });
        const turkishAlphabet = [
          'A','B','C','Ç','D','E','F','G','Ğ','H','I','İ',
          'J','K','L','M','N','O','Ö','P','R','S','Ş','T',
          'U','Ü','V','Y','Z',
        ];
        const randomLetter = turkishAlphabet[Math.floor(Math.random() * turkishAlphabet.length)];
        lettersArray.push({ value: randomLetter, x, y });
      } else {
        // Overlap varsa aynı i'yi tekrar dene
        i--;
      }
    }

    setLetters(lettersArray);
    setShowLetters(true);

    // Instead of displayDuration, we use speedFactor
    const computedDisplayDuration = baseDisplayDuration / speedFactor;
    setTimeout(() => {
      setShowLetters(false);
      clearCanvas();
    }, computedDisplayDuration);
  };

  // ─────────────────────────────────────────────────────────────────
  //  SHUFFLE ARRAY HELPER
  // ─────────────────────────────────────────────────────────────────
  const shuffleArray = (array) => {
    const shuffled = [...array];
    for (let i = shuffled.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [shuffled[i], shuffled[j]] = [shuffled[j], shuffled[i]];
    }
    return shuffled;
  };

  // ─────────────────────────────────────────────────────────────────
  //  SUBMIT ANSWER
  // ─────────────────────────────────────────────────────────────────
  const handleSubmit = (e) => {
    e.preventDefault();

    // TR karakterlere göre temizleme + uppercase
    let userInput = input
      .replace(/[^a-zA-ZığüşöçİĞÜŞÖÇ]/gi, '')
      .toLocaleUpperCase('tr')
      .split('');

    let correctLetters = letters.map((l) => l.value.toLocaleUpperCase('tr'));

    userInput.sort();
    correctLetters.sort();

    const isCorrect =
      userInput.length === correctLetters.length &&
      userInput.every((letter, idx) => letter === correctLetters[idx]);

    setAttempts((prev) => [
      ...prev,
      {
        correctLetters: [...correctLetters],
        userInput: [...userInput],
        isCorrect,
      },
    ]);

    if (isCorrect) {
      setScore((prev) => prev + 1);
      setInput('');
      generateLetters();
    } else {
      alert('Yanlış cevap!');
      setInput('');
      generateLetters();
    }
  };

  // ─────────────────────────────────────────────────────────────────
  //  DRAW & CLEAR CANVAS
  // ─────────────────────────────────────────────────────────────────
  const drawCanvas = () => {
    const canvas = canvasRef.current;
    if (!canvas) return;
    const ctx = canvas.getContext('2d');
    const centerX = canvas.width / 2;
    const centerY = canvas.height / 2;

    clearCanvas();

    // Merkezde küçük nokta
    ctx.beginPath();
    ctx.arc(centerX, centerY, 10, 0, 2 * Math.PI);
    ctx.fillStyle = '#888';
    ctx.fill();

    letters.forEach((letterObj) => {
      ctx.fillStyle = '#007acc';
      ctx.font = 'bold 34px Arial';      
      ctx.textAlign = 'center';
      ctx.textBaseline = 'middle';
      ctx.fillText(letterObj.value, letterObj.x, letterObj.y);
    });
  };

  const clearCanvas = () => {
    const canvas = canvasRef.current;
    if (canvas) {
      const ctx = canvas.getContext('2d');
      ctx.clearRect(0, 0, canvas.width, canvas.height);
    }
  };

  // ─────────────────────────────────────────────────────────────────
  //  NAVIGATION
  // ─────────────────────────────────────────────────────────────────
  const handleReturnToPreviousStage = () => {
    if (stage) {
      navigate(
        userRole === 'student' ? '/ogrenci-dashboard' : '/ogretmen-dashboard',
        {
          state: {
            selectedMenu: 'ExerciseList',
            selectedStage: stage,
          },
        }
      );
    } else {
      alert('Aşama bilgisi bulunmamaktadır.');
    }
  };

  // ─────────────────────────────────────────────────────────────────
  //  PROGRESS UPDATE
  // ─────────────────────────────────────────────────────────────────
  const updateProgress = async () => {
    if (!userData || !userRole) return;
    const gameTime = totalTime - timeLeft;

    try {
      const payload = {
        stageNumber: stage?.number || 1,
        gameName: exercise?.gameName || 'Hangi Harfi Gördün?',
        score: score,
        time: gameTime,
        completed: true,
      };

      if (userRole === 'student') {
        const apiUrl = `${API_URLS.STUDENT_URL}akillikurs/progress/update-game`;
        await axiosInstance.post(apiUrl, payload);
      } else {
        const token = localStorage.getItem('userToken');
        const apiUrl = `${API_URLS.TEACHER_URL}akillikurs/progress/update-game`;
        await axios.post(apiUrl, payload, {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        });
      }
    } catch (error) {
      console.error('İlerleme güncellenirken hata oluştu:', error);
    }
  };

  // ─────────────────────────────────────────────────────────────────
  //  DIFFICULTY CHANGE
  // ─────────────────────────────────────────────────────────────────
  const handleDifficultyChange = (e) => {
    const newDifficulty = e.target.value;
    setDifficulty(newDifficulty);
    if (gameActive) {
      resetGame();
    }
  };

  // ─────────────────────────────────────────────────────────────────
  //  MODAL
  // ─────────────────────────────────────────────────────────────────
  const handleClose = () => {
    setShowModal(false);
  };

  // ─────────────────────────────────────────────────────────────────
  //  RENDER
  // ─────────────────────────────────────────────────────────────────
  return (
    <div className="hangi-harfi-gordun-container game-container-bg">
      {showIntroduction ? (
       <div
       className="
         intro-container
         w-full
         max-w-2xl
         mx-auto
         mt-4
         p-4
         md:p-6
          text-center
       "
     >
       <GameHeader
         handleReturnToPreviousStage={handleReturnToPreviousStage}
         stage={stage}
       />
     
       {/* Başlık */}
       <h1 className="intro-titl mt-4 text-2xl font-bold mb-2 ">
         Hangi Harfi Gördün?
       </h1>
     
       {/* Açıklama metni */}
       <p className="intro-description text-base mb-4">
         Bu egzersizde, ekranda kısa bir süreliğine gösterilen harfleri hatırlayıp
         doğru şekilde girmeye çalışacaksınız.
       </p>
     
       {/* Talimatlar kutusu */}
       <div
         className="
           intro-instructions-box
           bg-white
           p-6
           rounded-md
           shadow
           w-full
           max-w-xl
           mb-4
         "
       >
         <h3
           className="
             intro-instructions-title
             text-lg
             font-semibold
             mb-3
           "
         >
           Talimatlar
         </h3>
         <ol
           className="
             intro-instructions-list
             list-decimal
             list-inside
             pl-5
             text-sm
             text-gray-700
             space-y-2
           "
         >
           <li>Harfler ekranda belirecek, dikkatlice izleyin.</li>
           <li>Harfler kaybolduktan sonra gördüğünüz harfleri girin.</li>
           <li>Zorluk seviyesini seçerek egzersizi özelleştirebilirsiniz.</li>
         </ol>
       </div>
     
       {/* Başlat butonu */}
       <button
         onClick={enterGame}
         className="
           intro-button
           bg-blue-600
           hover:bg-blue-700
           text-white
           font-bold
           px-5
           py-2
           rounded-md
           shadow-lg
           transition-transform
           duration-100
           active:scale-95
           mt-4
         "
       >
         BAŞLAT
       </button>
     </div>
     
      ) : (
        <div className="hangi-harfi-gordun-gameplay">
          <div className="hangi-harfi-gordun-card">
            <div className="hangi-harfi-gordun-card-body">
              <GameHeader
                handleReturnToPreviousStage={handleReturnToPreviousStage}
                stage={stage}
              />

              <div className="hangi-harfi-gordun-setting mt-2">
             
                  <label htmlFor="difficultySelect"
                  className='game-select-label'
                  >Zorluk Seviyesi:</label>
                  <select
                    className="game-select"
                    id="difficultySelect"
                    value={difficulty}
                    onChange={handleDifficultyChange}
                  >
                    <option value="kolay">Kolay</option>
                    <option value="orta">Orta</option>
                    <option value="zor">Zor</option>
                  </select>               
              </div> 
  
              <div className="game-buttons">
                  {gameActive ? (
                    <>
                      <button onClick={stopGame} className="game-button">
                        Durdur
                      </button>
                      <button onClick={resetGame} className="game-button">
                        Yeniden Başlat
                      </button>
                    </>
                  ) : (
                    <>
                      <button onClick={startGame} className="game-button">
                        Başlat
                      </button>
                      <button onClick={resetGame} className="game-button">
                        Yeniden Başlat
                      </button>
                    </>
                  )}
                </div>

              <div className="hangi-harfi-gordun-status">
                <p>Kalan Süre: {timeLeft} saniye</p>
                <p>Skor: {score}</p>
              </div>

              <div className="hangi-harfi-gordun-canvas-container">
                <canvas
                  ref={canvasRef}
                  width={400}
                  height={400}
                  className="hangi-harfi-gordun-canvas"
                  style={{ display: gameActive ? 'block' : 'none' }}
                />
                {gameActive && !showLetters && (
                  <form onSubmit={handleSubmit} className="hangi-harfi-gordun-input-form">
                    <input
                      ref={inputRef}
                      type="text"
                      value={input}
                      onChange={(e) =>
                        setInput(e.target.value.toLocaleUpperCase('tr'))
                      }
                      placeholder="Gördüğün harfleri gir"
                      style={{ textTransform: 'uppercase' }}
                    />
                    <button type="submit" className="hangi-harfi-gordun-button">
                      Gönder
                    </button>
                  </form>
                )}
              </div>
            </div>
          </div>
        </div>
      )}

      {showModal && (
        <GameOverModal
          score={score}
          onRestart={resetGame}
          userData={userData}
          onClose={handleClose}
          attempts={attempts}
          totalTime={totalTime}
        />
      )}
    </div>
  );
}

export default HangiHarfiGordun;
