import React from 'react';

const Description = () => {
  return (
    <div
      className="
        intro-instructions-box
        bg-white
        p-6
        rounded-md
        shadow
        w-full
        max-w-xl
        mb-4
      "
    >
      <h3
        className="
          intro-instructions-title
          text-lg
          font-semibold
          mb-3
          text-[#211C84]
        "
      >
        Talimatlar
      </h3>
      <ol
        className="
          intro-instructions-list
          list-decimal
          list-inside
          pl-5
          text-sm
          text-gray-700
          space-y-2
        "
      >
        <li>Çalışmayı başlatmak için "Başlat" butonuna basın.</li>
        <li>Size verilen paragrafta eksik kelimeleri tamamlayın.</li>
        <li>Seçeneklerden doğru kelimeleri seçin ve boşluklara yerleştirin.</li>
        <li>Çalışma süresi dolmadan tüm kelimeleri tamamlamaya çalışın.</li>
      </ol>
    </div>
  );
};

export default Description;
