import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faHome, faCog } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router-dom';

const HeaderSection = ({ stage, handleReturnToPreviousStage, openSettings, hideSettings }) => {
  const navigate = useNavigate();

  const handleBackButton = () => {
    if (stage) {
      handleReturnToPreviousStage();
    } else {
      navigate(-1);
    }
  };

  const backButtonLabel = stage ? 'Önceki Aşamaya Dön' : 'Geri Dön';

  return (
  
    <div className="flex flex-wrap justify-center items-center gap-2 p-2">
      {/* Geri Butonu */}
      <button
        onClick={handleBackButton}
        aria-label={backButtonLabel}
        className="
          bg-[#274989]
          hover:bg-[#3e38b3]
          text-white
          font-bold
          transition-colors
          duration-300
          cursor-pointer
          px-3 py-2 text-sm
          sm:px-4 sm:py-2 sm:text-base
          rounded-md
          flex
          items-center
        "
      >
        <FontAwesomeIcon icon={faArrowLeft} className="w-4 h-4" />
        <span className="hidden sm:inline-block ml-1">{backButtonLabel}</span>
      </button>

      {/* Ana Sayfa Butonu */}
      <button
        onClick={() => navigate('/')}
        aria-label="Ana Sayfa"
        className="
          bg-[#274989]
          hover:bg-[#3e38b3]
          text-white
          font-bold
          transition-colors
          duration-300
          cursor-pointer

          px-3 py-2 text-sm
          sm:px-4 sm:py-2 sm:text-base

          rounded-md
          flex
          items-center
        "
      >
        <FontAwesomeIcon icon={faHome} className="w-4 h-4" />
        {/* Ana Sayfa metnini de aynı şekilde gizleyip gösterebilirsiniz */}
        <span className="hidden sm:inline-block ml-1">Ana Sayfa</span>
      </button>
      {!hideSettings && (
        <button
          onClick={openSettings}
          aria-label="Ayarlar"
          className="
            bg-[#274989]
            hover:bg-[#3e38b3]
            text-white
            font-bold
            transition-colors
            duration-300
            cursor-pointer
            px-3 py-2 text-sm
            sm:px-4 sm:py-2 sm:text-base
            rounded-md
            flex
            items-center
          "
        >
          <FontAwesomeIcon icon={faCog} className="w-4 h-4" />
          <span className="hidden sm:inline-block ml-1">Ayarlar</span>
        </button>
      )}
    </div>
  );
};

export default HeaderSection;
