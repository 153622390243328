// GameArea.js

import React from 'react';
import '../styles/tachistoscopicReadingStyle.css';

const GameArea = ({
  phase,
  showingTime,
  writingTime,
  totalShowingTime,
  totalWritingTime,
  isPlaying,
  currentWord,
  input,
  handleInputChange,
  handleKeyPress,
  enteredWords,
  handleSubmit,
  isFinished,
}) => {
  const showingProgress = ((showingTime / totalShowingTime) * 100).toFixed(0);
  const writingProgress = ((writingTime / totalWritingTime) * 100).toFixed(0);

  return (
    <>
      <div className="tachisto-status">
        {phase === 'showing' ? (
          <>
         <div className="timer mt-4 text-center tracking-[1px] leading-[1.6]">
          <span className="block font-bold text-base sm:text-lg md:text-2xl lg:text-[2.2rem]">
            Kalan Süre: {Math.ceil(showingTime)} sn
          </span>
        </div>

            <div className="tachisto-progress-bar-container">
              <div
                 className="tachisto-progress-bar-fill"
                style={{ width: `${(showingProgress <= 0 ? 0 : showingProgress)}%` }}
              ></div>
            </div>
          </>
        ) : (
          <>
            <div className="timer mt-4">
             <span> Yazma Süresi: {writingTime} sn </span>
              </div>
            <div   className="progress-bar" >
              <div
                className="progress-fill"
                style={{ width: `${(writingProgress <= 0 ? 0 : writingProgress)}%` }}
              ></div>
            </div>
          </>
        )}
      </div>
      {isPlaying && phase === 'showing' && (
        <div className="tachisto-word-display">{currentWord}</div>
      )}
      {phase === 'writing' && (
        <div className="tachisto-input-container">
          <input
            type="text"
            value={input}
            onChange={handleInputChange}
            onKeyDown={handleKeyPress}
            placeholder="Hatırladığınız kelimeleri girin ve boşluk bırakın"
            className="tachisto-input"
            disabled={isFinished || writingTime === 0}
          />
        </div>
      )}
      {phase === 'writing' && (
        <div className="tachisto-entered-words">
        <div className="timer mt-4">
        <span> Girilen Kelimeler:  </span> </div>
          <p>{enteredWords.join(', ')}</p>
        </div>
      )}
      {phase === 'writing' && (
        <div className="tachisto-game-controls">
          <button className="tachisto-button" onClick={handleSubmit}>
            Sonucu Göster
          </button>
        </div>
      )}
    </>
  );
};

export default GameArea;
