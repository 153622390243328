import React, { useState, useEffect } from 'react';
import HeaderSection from './components/HeaderSection';
import GameOptions from './components/GameOptions';
import SpeedSliderAndScore from './components/SpeedSliderAndScore';
import SpeedControl from './components/SpeedControl';
import GameTimer from './components/GameTimer';
import useGameLogic from './hooks/gameLogic';
import GameOverModal from './components/GameOverModal';
import axios from 'axios';
import axiosInstance from '../../../utils/axiosInstance';
import { API_URLS } from '../../../config/config';
import { useNavigate, useLocation } from 'react-router-dom';
import './styles/zigzagTrackerStyle.css'; 

function ZigzagTracker() {
  const navigate = useNavigate();
  const location = useLocation();
  const { stage, exercise } = location.state || {};
  const token = localStorage.getItem('userToken');

  const [userData, setUserData] = useState(null);
  const [userRole, setUserRole] = useState(null);

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const studentResponse = await axiosInstance.get(`${API_URLS.STUDENT_URL}/me`);
        if (
          studentResponse.status === 200 &&
          studentResponse.data &&
          studentResponse.data.status === 'active'
        ) {
          const studentData = studentResponse.data;
          setUserData(studentData);
          setUserRole('student');
          return;
        } else {
          setUserData(null);
          setUserRole(null);
        }
      } catch (error) {
        console.error('Öğrenci verisi alınırken hata oluştu:', error);
      }

      try {
        const teacherResponse = await fetch(`${API_URLS.TEACHER_URL}/me`, {
          method: 'GET',
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        });
        if (teacherResponse.ok) {
          const teacherData = await teacherResponse.json();
          setUserData(teacherData);
          setUserRole('teacher');
          return;
        }
      } catch (error) {
        console.error('Öğretmen verisi alınırken hata oluştu:', error);
      }

      setUserData(null);
      setUserRole(null);
    };

    fetchUserData();
  }, [token]);

  const onGameOver = () => {
    setIsPlaying(false);
    setShowModal(true);
    updateProgress();
  };

  const updateProgress = async () => {
    if (!userData || !userRole) return;
    try {
      const payload = {
        stageNumber: stage?.number || 1,
        gameName: exercise?.gameName || 'Zigzag Takip Et',
        score: Math.floor(score),
        time: (userData?.status === 'inactive' ? 15 : 60) - timeRemaining,
        completed: true,
      };

      if (userRole === 'student') {
        // Öğrenci endpoint
        const apiUrl = `${API_URLS.STUDENT_URL}akillikurs/progress/update-game`;
        await axiosInstance.post(apiUrl, payload);
      } else {
        // Öğretmen endpoint
        const apiUrl = `${API_URLS.TEACHER_URL}akillikurs/progress/update-game`;
        const teacherToken = localStorage.getItem('userToken');
        await axios.post(apiUrl, payload, {
          headers: {
            Authorization: `Bearer ${teacherToken}`,
            'Content-Type': 'application/json',
          },
        });
      }

      console.log('İlerleme başarıyla güncellendi.');
    } catch (error) {
      console.error('İlerleme güncellenirken hata oluştu:', error);
    }
  };

  const {
    canvasRef,
    isPlaying,
    setIsPlaying,
    speed,
    setSpeed,
    orientation,
    setOrientation,
    canvasSize,
    score,
    timeRemaining,
    setTimeRemaining,
    difficulty,
    handleDifficultyChange,
    resetGame,
    showModal,
    setShowModal,
  } = useGameLogic(userData, onGameOver);

  const initialGameTime = userData && userData.status !== 'inactive' ? 60 : 15;
  const [showIntroduction, setShowIntroduction] = useState(true);

  const startGame = () => {
    setShowIntroduction(false);
    resetGame();
  };

  useEffect(() => {
    if (timeRemaining === 0 && isPlaying) {
      onGameOver();
    }
  }, [timeRemaining, isPlaying]);

  const handleReturnToPreviousStage = () => {
    if (stage) {
      navigate(
        userRole === 'student' ? '/ogrenci-dashboard' : '/ogretmen-dashboard',
        {
          state: {
            selectedMenu: 'ExerciseList',
            selectedStage: stage,
          },
        }
      );
    } else {
      alert('Aşama bilgisi bulunmamaktadır.');
    }
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  return (
    <>
      {showIntroduction ? (
      <div
      className="
    
        game-container-bg
        min-h-screen
        flex
        flex-col
        items-center
        justify-center
        px-4
        sm:px-6
        md:px-8
        lg:px-12
         text-center
      "
    >
      <div
        className="
          intro-container
          w-full
          max-w-2xl
          mx-auto
          p-4
          md:p-6
        "
      >
        {/* Üstteki geri dön vb. butonlar için */}
        <HeaderSection
          handleReturnToPreviousStage={handleReturnToPreviousStage}
          stage={stage}
        />
    
        {/* Başlık */}
        <h1 className="intro-title mt-4 text-2xl font-bold mb-2">
          Zigzag Takip Egzersizi
        </h1>
    
        {/* Açıklama Metni */}
        <p className="intro-description text-base mb-4">
          Bu egzersinin amacı, hareket eden zigzag desenini gözlerinizle takip etmektir.
        </p>
    
        {/* Talimatlar Kutusu */}
        <div
          className="
            intro-instructions-box
            bg-white
            p-6
            rounded-md
            shadow
            w-full
            max-w-xl
            mb-4
          "
        >
          <h3
            className="
              intro-instructions-title
              text-lg
              font-semibold
              mb-3
            "
          >
            Talimatlar
          </h3>
          <ul
            className="
              intro-instructions-list
              list-none
              text-sm
              text-gray-700
              space-y-2
            "
          >
            <li>1. Hareket eden zigzag desenini gözlerinizle izleyin.</li>
            <li>2. Hızla yön değiştiren objeyi gözden kaçırmayın.</li>
            <li>3. Hızı artırarak ve yönü değiştirerek zorluğu yükseltin.</li>
          </ul>
        </div>
    
        {/* Başlat Butonu */}
        <button
          className="
            intro-button
            bg-blue-600
            hover:bg-blue-700
            text-white
            font-bold
            px-5
            py-2
            rounded-md
            shadow-lg
            transition-transform
            duration-100
            active:scale-95
            mt-4
          "
          onClick={startGame}
        >
          BAŞLAT
        </button>
      </div>
    </div>
    
      ) : (
        <div className="zigzag-container game-container-bg">
          {/* Header + Oyun Alanı */}
          <HeaderSection handleReturnToPreviousStage={handleReturnToPreviousStage}
            stage={stage}
          />

          <div className="zigzag-status">
            <div className="zigzag-status-left">
              <GameTimer
                isPlaying={isPlaying}
                timeRemaining={timeRemaining}
                initialGameTime={initialGameTime}
                setTimeRemaining={setTimeRemaining}
              />
            </div>
            <div className="zigzag-status-right">
              {userData && userData.status !== 'inactive' && (
                <SpeedSliderAndScore score={score} />
              )}
            </div>
          </div>

          <div className="zigzag-circle-container">
           <div className="zigzag-canvas-container">
              <canvas
                ref={canvasRef}
                width={canvasSize.width}
                height={canvasSize.height}
                className="zigzag-game-canvas"
              />
            </div>
          </div>

          <div className="zigzag-control-row">
            <GameOptions
              isPlaying={isPlaying}
              setIsPlaying={setIsPlaying}
              resetGame={resetGame}
              orientation={orientation}
              setOrientation={setOrientation}
              difficulty={difficulty}
              handleDifficultyChange={handleDifficultyChange}
            />
            <SpeedControl speed={speed} setSpeed={setSpeed} />
          </div>
        </div>
      )}

      {showModal && (
        <GameOverModal
          score={score}
          onRestart={resetGame}
          userData={userData}
          onClose={handleCloseModal}
        />
      )}
    </>
  );
}

export default ZigzagTracker;
