import React from 'react';

const GameTimer = ({ timeLeft }) => {
  const formatTime = (ms) => {
    let totalSeconds = Math.ceil(ms / 1000);
    if (totalSeconds < 0) totalSeconds = 0;
    const minutes = Math.floor(totalSeconds / 60)
      .toString()
      .padStart(2, '0');
    const seconds = (totalSeconds % 60).toString().padStart(2, '0');
    return `${minutes}:${seconds}`;
  };

  return (
    <div className="timer mt-4 text-center tracking-[1px] leading-[1.6]">
      <span className="block font-bold text-base sm:text-lg md:text-2xl lg:text-[2.2rem]">
        Kalan Süre: {formatTime(timeLeft)}
      </span>
    </div>
  );
};

export default GameTimer;
