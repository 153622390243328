import React from 'react';

const LetterGrid = ({
  letters,
  targetLetters,
  clickedLetters,
  handleLetterClick,
  gridSize,
}) => {
  const gridColumns = Math.sqrt(gridSize);

  return (
    <div className="text-center w-full flex flex-col items-center">     
      <div
        className="
          harf-izgarasi-target-letter
          text-lg 
          font-bold
          border-2 
          border-dashed 
          px-2 
          py-2 
          rounded 
          mb-2
          inline-block
          text-[var(--text-color)]
          border-[var(--text-color)]
        "
      >
        Hedef Harfler: {targetLetters.join(', ')}
      </div>
   
      <div
        className="
          letter-grid-container
          w-full
          max-w-sm
          sm:max-w-md
          md:max-w-lg
          mx-auto
          border 
          border-orange-500
          rounded-md
          overflow-auto
          flex
          justify-center
          items-center
          bg-[var(--card-background)]
          p-2
          gap-2
        "
      >
        <div
          style={{
            gridTemplateColumns: `repeat(${gridColumns}, minmax(0, 1fr))`,
            gridAutoRows: '1fr',
          }}
          className="
            harf-izgarasi-letter-grid
            grid
            w-full
            h-auto
            p-2
            gap-2
          "
        >
          {letters.map((letter, index) => {
            const isClicked = clickedLetters.includes(index);
            const isCorrect = targetLetters.includes(letter);

            let letterClass = 'bg-gray-200 text-black';
            if (isClicked) {
              letterClass = isCorrect
                ? 'bg-green-500 text-white'
                : 'bg-red-500 text-white';
            }

            return (
              <span
                key={index}
                onClick={() => handleLetterClick(letter, index)}
                className={`
                  harf-izgarasi-letter
                  font-bold
                  cursor-pointer
                  border-2 
                  border-gray-500
                  rounded-md
                  flex 
                  items-center 
                  justify-center
                  transition 
                  transform 
                  hover:scale-105
                  aspect-square
                  text-base
                  ${letterClass}
                `}
              >
                {letter}
              </span>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default LetterGrid;
