import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate, useLocation } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import { fetchParagraphs } from './hooks/apiService';
import { useGameLogic } from './hooks/useGameLogic';
import NavigationButtons from './components/NavigationButtons';
import GameBoard from './components/GameBoard';
import MessageDisplay from './components/MessageDisplay';
import GameExplanation from './components/GameExplanation';
import GameOverModal from './components/GameOverModal';
import SettingsModalKelimeBulma from './components/SettingsModalKelimeBulma';
import './styles/KelimeBulmaOyunu.css';
import { API_URLS } from '../../../config/config';
import axiosInstance from '../../../utils/axiosInstance';

const KelimeBulmaOyunu = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { stage, exercise } = location.state || {};
  const token = localStorage.getItem('userToken');
  const [userData, setUserData] = useState(null);
  const [userRole, setUserRole] = useState(null);
  const [showInstructions, setShowInstructions] = useState(true);
  const [showGameOverModal, setShowGameOverModal] = useState(false);
  const [showSettingsModal, setShowSettingsModal] = useState(false);
  const toggleSettingsModal = () => {
    setShowSettingsModal(!showSettingsModal);
  };

  // -- Aşamaya göre zorluk
  const stageNumber = stage?.number || null;
  let initialDifficulty = 'easy';
  let difficultyLocked = false;
  if (stageNumber) {
    difficultyLocked = true;
    if (stageNumber >= 1 && stageNumber <= 7) {
      initialDifficulty = 'easy';
    } else if (stageNumber >= 8 && stageNumber <= 14) {
      initialDifficulty = 'medium';
    } else {
      initialDifficulty = 'hard';
    }
  }

  // State’ler
  const [difficulty, setDifficulty] = useState(initialDifficulty);
  const [paragraphsList, setParagraphsList] = useState([]);
  const [selectedParagraphIndex, setSelectedParagraphIndex] = useState(0);
  const [paragraph, setParagraph] = useState('');
  const [targetWords, setTargetWords] = useState([]);

  // Tema - Font - FontSize
  const [theme, setTheme] = useState('default');
  const [font, setFont] = useState('Arial');
  const [fontSize, setFontSize] = useState('1rem');

  // Hız ayarı -> GameControls’da kullanılacak
  const [speedFactor, setSpeedFactor] = useState(1);

  // Kullanıcı verisi çek
  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const studentResponse = await axiosInstance.get(`${API_URLS.STUDENT_URL}/me`);
        if (
          studentResponse.status === 200 &&
          studentResponse.data &&
          studentResponse.data.status === 'active'
        ) {
          const studentData = studentResponse.data;
          setUserData(studentData);
          setUserRole('student');
          return;
        } else {
          setUserData(null);
          setUserRole(null);
        }
      } catch (error) {
        console.error('Öğrenci verisi alınırken hata oluştu:', error);
      }

      // Öğrenci yoksa öğretmene bak
      try {
        const teacherResponse = await fetch(`${API_URLS.TEACHER_URL}/me`, {
          method: 'GET',
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        });
        if (teacherResponse.ok) {
          const teacherData = await teacherResponse.json();
          setUserData(teacherData);
          setUserRole('teacher');
          return;
        }
      } catch (error) {
        console.error('Öğretmen verisi alınırken hata oluştu:', error);
      }

      setUserData(null);
      setUserRole(null);
    };

    fetchUserData();
  }, [token]);

  // Zorluk değişince paragraf çek
  useEffect(() => {
    async function fetchData() {
      try {
        const data = await fetchParagraphs(difficulty);
        setParagraphsList(data);
        if (data.length > 0) {
          setSelectedParagraphIndex(0);
        } else {
          setParagraph('');
          setTargetWords([]);
        }
      } catch (error) {
        console.error('Paragraflar alınırken hata:', error);
      }
    }
    fetchData();
  }, [difficulty]);

  // Paragraf / indeks değişince paragraf ve target kelimeler
  useEffect(() => {
    if (paragraphsList.length > 0) {
      const rawParagraphContent =
        paragraphsList[selectedParagraphIndex]?.content || '';

      setParagraph(rawParagraphContent);

      const tempDiv = document.createElement('div');
      tempDiv.innerHTML = rawParagraphContent;
      const plainTextParagraph = tempDiv.textContent || tempDiv.innerText || '';

      const wordsArray = plainTextParagraph.match(/[\p{L}\p{N}]+/gu) || [];
      const words = new Set(
        wordsArray.map((w) =>
          w.toLowerCase().replace(/[^\p{L}\p{N}_]+/gu, '')
        )
      );
      // 3 veya daha fazla harfli kelimeler
      setTargetWords(
        Array.from(words)
          .filter((word) => word.length >= 3)
          .sort(() => 0.5 - Math.random())
          .slice(0, 5)
      );
    } else {
      setParagraph('');
      setTargetWords([]);
    }
  }, [paragraphsList, selectedParagraphIndex]);

  // Oyun mantığı
  const {
    selectedWords,
    correctWords,
    incorrectWords,
    isPlaying,
    timeRemaining,
    score,
    remainingAttempts,
    message,
    handleWordClick,
    handlePlayPause,
    handleReset,
  } = useGameLogic(
    paragraphsList,
    selectedParagraphIndex,
    targetWords,
    userData,
    paragraph
  );

  const startGame = () => {
    setShowInstructions(false);
    handleReset();
  };

  const handleRestart = () => {
    setShowGameOverModal(false);
    handleReset();
  };

  const onGameOver = () => {
    setShowGameOverModal(true);
    updateProgress();
  };

  useEffect(() => {
    if (
      timeRemaining === 0 ||
      remainingAttempts === 0 ||
      (correctWords.length === targetWords.length && targetWords.length > 0)
    ) {
      onGameOver();
    }
  }, [timeRemaining, remainingAttempts, correctWords, targetWords]);

  const updateProgress = async () => {
    try {
      if (userData && userRole) {
        const payload = {
          stageNumber: stage?.number || 1,
          gameName: exercise?.gameName || 'Kelime Bulma ve Seçme',
          score: score,
          time:
            (userData && userData.status === 'inactive' ? 15 : 60) -
            timeRemaining,
          wordCount: correctWords.length,
          completed: true,
        };

        if (userRole === 'student') {
          const apiUrl = `${API_URLS.STUDENT_URL}akillikurs/progress/update-game`;
          await axiosInstance.post(apiUrl, payload);
        } else {
          const apiUrl = `${API_URLS.TEACHER_URL}akillikurs/progress/update-game`;
          const teacherToken = localStorage.getItem('userToken');
          await axios.post(apiUrl, payload, {
            headers: {
              Authorization: `Bearer ${teacherToken}`,
              'Content-Type': 'application/json',
            },
          });
        }
      }
    } catch (error) {
      console.error('İlerleme güncellenirken hata oluştu:', error);
    }
  };

  const handleReturnToPreviousStage = () => {
    if (stage) {
      navigate(
        userRole === 'student' ? '/ogrenci-dashboard' : '/ogretmen-dashboard',
        {
          state: {
            selectedMenu: 'ExerciseList',
            selectedStage: stage,
          },
        }
      );
    } else {
      alert('Aşama bilgisi bulunmamaktadır.');
    }
  };

  // Difficulty & Paragraph => modal üzerinden
  const onDifficultyChange = (newDifficulty) => {
    if (difficultyLocked) return;
    setDifficulty(newDifficulty);
    setSelectedParagraphIndex(0);
  };

  const onParagraphChange = (newIndex) => {
    setSelectedParagraphIndex(parseInt(newIndex));
  };

  // Modal kapatma
  const handleCloseGameOverModal = () => setShowGameOverModal(false);
  const handleCloseSettingsModal = () => setShowSettingsModal(false);

  // HIZ AYARI => GameControls
  const handleSpeedIncrease = () => {
    setSpeedFactor((prev) => Math.min(prev + 0.1, 3));
  };
  const handleSpeedDecrease = () => {
    setSpeedFactor((prev) => Math.max(prev - 0.1, 0.5));
  };

  return (
    <div
      className={`game-container-bg theme-${theme}`}
      style={{ fontFamily: font, fontSize }}
    >
  

      {showInstructions ? (
    <div
    className={`
      game-container-bg
       bg-[size:100%_100%,_20px_20px]
    md:bg-[size:150%_150%,_30px_30px]
    lg:bg-[size:300%_200%,_40px_40px]
      theme-${theme}  
      min-h-screen
      flex
      flex-col
      items-center
      justify-center  
      lg:justify-start
      lg:pt-10  
      px-4
      sm:px-6
      md:px-8
      lg:px-12
    `}
    style={{ fontFamily: font, fontSize }}
  >
    {showInstructions ? (
      <div
        className="
          intro-container
          w-full
          max-w-2xl
          mx-auto
          mt-4
          p-4
          md:p-6
           text-center
        "
      >
        <NavigationButtons
          stage={stage}
          handleReturnToPreviousStage={handleReturnToPreviousStage}
          handleSettingsClick={toggleSettingsModal}
          hideSettings={true}
        />
  
        <h1 className="intro-title mt-4 text-2xl font-bold mb-2">
          Kelime Bulma ve Seçme Çalışması
        </h1>
  
        {stage ? (
          <p className="intro-description text-base mb-4">
            Bu çalışmada aşamanıza göre zorluk seviyesi otomatik belirlenecek.
            Metindeki hedef kelimeleri bulmaya çalışın.
          </p>
        ) : (
          <GameExplanation />
        )}
  
        <button
          className="
            intro-button
            bg-[var(--button-background)]
            hover:bg-[var(--button-hover)]
            text-white
            font-bold
            px-5
            py-2
            rounded-md
            shadow-lg
            transition-transform
            duration-100
            active:scale-95
            mt-2
          "
          onClick={startGame}
        >
          BAŞLAT
        </button>
      </div>
    ) : (
      <div className="kbo-card mt-4">
        <div className="kbo-card-body">
          <NavigationButtons
            stage={stage}
            handleReturnToPreviousStage={handleReturnToPreviousStage}
            handleSettingsClick={toggleSettingsModal}
          />
          <GameBoard
            paragraph={paragraph}
            selectedWords={selectedWords}
            targetWords={targetWords}
            handleWordClick={handleWordClick}
            correctWords={correctWords}
            timeRemaining={timeRemaining}
            isPlaying={isPlaying}
            handlePlayPause={handlePlayPause}
            handleReset={handleReset}
          />
  
          <MessageDisplay message={message} />
        </div>
      </div>
    )}
  
    {showGameOverModal && (
      <GameOverModal
        score={score}
        onRestart={handleRestart}
        onClose={handleCloseGameOverModal}
        userData={userData}
      />
    )}
  
    {showSettingsModal && (
      <SettingsModalKelimeBulma
        onClose={handleCloseSettingsModal}
        difficulty={difficulty}
        difficultyLocked={difficultyLocked}
        paragraphsList={paragraphsList}
        selectedParagraphIndex={selectedParagraphIndex}
        onDifficultyChange={onDifficultyChange}
        onParagraphChange={onParagraphChange}
        theme={theme}
        setTheme={setTheme}
        font={font}
        setFont={setFont}
        fontSize={fontSize}
        setFontSize={setFontSize}
      />
    )}
  </div>
  
    
      ) : (
        <div >
          <div >
          <NavigationButtons
            stage={stage}
            handleReturnToPreviousStage={handleReturnToPreviousStage}
            handleSettingsClick={toggleSettingsModal}
           />
            <GameBoard
              paragraph={paragraph}
              selectedWords={selectedWords}
              targetWords={targetWords}
              handleWordClick={handleWordClick}
              correctWords={correctWords}
              timeRemaining={timeRemaining}
              isPlaying={isPlaying}
              handlePlayPause={handlePlayPause}
              handleReset={handleReset}
            />

            <MessageDisplay message={message} />
          </div>
        </div>
      )}

      {showGameOverModal && (
        <GameOverModal
          score={score}
          onRestart={handleRestart}
          onClose={handleCloseGameOverModal}
          userData={userData}
        />
      )}

      {showSettingsModal && (
        <SettingsModalKelimeBulma
          onClose={handleCloseSettingsModal}
          difficulty={difficulty}
          difficultyLocked={difficultyLocked}
          paragraphsList={paragraphsList}
          selectedParagraphIndex={selectedParagraphIndex}
          onDifficultyChange={onDifficultyChange}
          onParagraphChange={onParagraphChange}
          theme={theme}
          setTheme={setTheme}
          font={font}
          setFont={setFont}
          fontSize={fontSize}
          setFontSize={setFontSize}
        />
      )}
    </div>
  );
};

export default KelimeBulmaOyunu;
