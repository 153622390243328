import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlay, faPause, faRedo } from '@fortawesome/free-solid-svg-icons';

const GameDisplay = ({
  isPlaying,
  handlePlayPause,
  handleReset,
  timeRemaining,
  paragraph,
  getParagraphWithBlanks,
  options,
  userSelections,
  handleWordSelect
}) => {
  // Süreyi mm:ss formatında göstermek isterseniz
  const formatTime = (seconds) => {
    if (seconds < 0) seconds = 0;
    const minutes = Math.floor(seconds / 60).toString().padStart(2, '0');
    const sec = (seconds % 60).toString().padStart(2, '0');
    return `${minutes}:${sec}`;
  };

  return (
    <>
      {/* Kalan Süre */}
      <div className="text-center tracking-[1px] leading-[1.6] mb-4">
        <span className="block font-bold text-base sm:text-lg md:text-2xl lg:text-3xl">
          Kalan Süre: {formatTime(timeRemaining)}
        </span>
      </div>

      {/* Oyun Kontrol Butonları */}
      <div className="flex items-center justify-center gap-4 mb-6">
        <button
          className="
            px-4 py-2
            rounded
            font-medium
            text-white
            bg-blue-600
            hover:bg-blue-700
            disabled:bg-gray-400
            disabled:cursor-not-allowed
            transition-colors
          "
          onClick={handlePlayPause}
          disabled={false} // İsterseniz burada farklı bir durum da ekleyebilirsiniz
        >
          <FontAwesomeIcon icon={isPlaying ? faPause : faPlay} />{' '}
          {isPlaying ? 'Duraklat' : 'Başlat'}
        </button>

        <button
          className="
            px-4 py-2
            rounded
            font-medium
            text-white
            bg-red-600
            hover:bg-red-700
            disabled:bg-gray-400
            disabled:cursor-not-allowed
            transition-colors
          "
          onClick={handleReset}
        >
          <FontAwesomeIcon icon={faRedo} /> Yeniden Başlat
        </button>
      </div>

      {/* Seçenek Kutucukları */}
      <div className="flex flex-wrap items-center justify-center gap-3 mb-6">
        {options.map((word, index) => {
          const isDisabled = Object.values(userSelections.filledWords).some(
            (filled) => filled.word === word && filled.isCorrect
          );
          const isSelected = userSelections.selectedWord === word;

          return (
            <button
              key={index}
              className={`
                
                px-4 py-2
                rounded
                border
                transition-colors
                ${
                  isSelected
                    ? 'bg-blue-600 text-white border-blue-600 hover:bg-blue-700'
                    : 'bg-transparent border-blue-600 text-blue-600 hover:bg-blue-600 hover:text-black'
                }
                ${isDisabled && 'opacity-50 cursor-not-allowed'}
              `}
              onClick={() => handleWordSelect(word)}
              disabled={!isPlaying || isDisabled}
            >
              {word}
            </button>
          );
        })}
      </div>

      <div className="text-center">
        {paragraph ? getParagraphWithBlanks() : 'Paragraf yükleniyor...'}
      </div>
    </>
  );
};

export default GameDisplay;
