import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlay, faPause, faRedo } from '@fortawesome/free-solid-svg-icons';

const GameControls = ({
  isPlaying,
  handlePlayPause,
  handleReset,
}) => {
  return (
   
    <div className="flex flex-wrap justify-center items-center gap-2 my-2">
      
  
      <button
        onClick={handlePlayPause}
        className="
          bg-[var(--button-background,#11318f)]
          hover:bg-[var(--button-hover,#0056b3)]
          text-white
          font-bold
          transition-colors
          duration-300
          cursor-pointer
          px-3 py-2 text-sm       
          sm:px-4 sm:py-2 sm:text-base
          rounded-md
          flex
          items-center
        "
      >
        <FontAwesomeIcon icon={isPlaying ? faPause : faPlay} className="w-4 h-4" />
       
        <span className="hidden sm:inline-block ml-1">
          {isPlaying ? 'Duraklat' : 'Başlat'}
        </span>
      </button>
        <button
        onClick={handleReset}
        className="
          bg-[var(--button-background,#11318f)]
          hover:bg-[var(--button-hover,#0056b3)]
          text-white
          font-bold
          transition-colors
          duration-300
          cursor-pointer

          px-3 py-2 text-sm
          sm:px-4 sm:py-2 sm:text-base

          rounded-md
          flex
          items-center
        "
      >
        <FontAwesomeIcon icon={faRedo} className="w-4 h-4" />
        <span className="hidden sm:inline-block ml-1">
          Yeniden Başlat
        </span>
      </button>
    </div>
  );
};

export default GameControls;
