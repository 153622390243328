import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faHome, faCog } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router-dom';

const HeaderSection = ({ stage, handleReturnToPreviousStage, toggleSettingsModal }) => {
  const navigate = useNavigate();

  const handleBackButton = () => {
    if (stage) {
      handleReturnToPreviousStage();
    } else {
      navigate(-1);
    }
  };

  const backButtonLabel = stage ? 'Önceki Aşamaya Dön' : 'Geri Dön';

  return (
    <div className="flex flex-wrap justify-center items-center gap-2 p-2">
      <button
        className="
          header-button
          bg-[#274989]
          hover:bg-[#3e38b3]
          text-white
          font-bold
          px-3 py-2 text-sm
          sm:px-4 sm:py-2 sm:text-base
          rounded-md
          flex
          items-center
          transition-colors
          duration-300
          cursor-pointer
        "
        onClick={handleBackButton}
        aria-label={backButtonLabel}
      >
        <FontAwesomeIcon icon={faArrowLeft} className="w-4 h-4" />
        <span className="hidden sm:inline-block ml-1">
          {backButtonLabel}
        </span>
      </button>
      
      <button
        className="
          header-button
          bg-[#274989]
          hover:bg-[#3e38b3]
          text-white
          font-bold
          px-3 py-2 text-sm
          sm:px-4 sm:py-2 sm:text-base
          rounded-md
          flex
          items-center
          transition-colors
          duration-300
          cursor-pointer
        "
        onClick={() => navigate('/')}
      >
        <FontAwesomeIcon icon={faHome} className="w-4 h-4" />
        <span className="hidden sm:inline-block ml-1">Ana Sayfa</span>
      </button>

      <button
        className="
          header-button
          bg-[#274989]
          hover:bg-[#3e38b3]
          text-white
          font-bold
          px-3 py-2 text-sm
          sm:px-4 sm:py-2 sm:text-base
          rounded-md
          flex
          items-center
          transition-colors
          duration-300
          cursor-pointer
        "
        onClick={toggleSettingsModal}
      >
        <FontAwesomeIcon icon={faCog} className="w-4 h-4" />
        <span className="hidden sm:inline-block ml-1">Ayarlar</span>
      </button>
    </div>
  );
};

export default HeaderSection;
