import React from 'react';

const GameStatus = ({ timeRemaining, score }) => {
  return (
    <div className="harf-izgarasi-status">
      {/* 
        mb-2 => Bottom margin azaldı (önce mb-5 idi)
        mt-1 => Top margin 0.25rem (önce mt-2 idi)
      */}
      <div className="text-center mb-2 tracking-[1px] leading-[1.6] mt-1">
        <div>       
          <span
            className="
              timer 
              block
              font-bold
              text-base 
              sm:text-lg
              md:text-2xl
              lg:text-[2.2rem]
            "
          >
            Kalan Süre: {timeRemaining} sn
          </span>
        </div>
        <div className="mt-2">
          <span
            className="
              font-bold
              text-base 
              sm:text-lg
              md:text-2xl
              lg:text-[2.2rem]
            "
          >
            Skor:
          </span>
          <span
            className="
              font-bold
              text-base 
              sm:text-lg
              md:text-2xl
              lg:text-[2.2rem]
              ml-2
            "
          >
            {score}
          </span>
        </div>
      </div>
    </div>
  );
};

export default GameStatus;
