import React from 'react';
import ProgressBarIkiser from './ProgressBarIkiser';

const GameTimerIkiser = ({ timeLeft, isPlaying, gameTime, elapsedTime }) => {
  const progress = gameTime > 0 
    ? (elapsedTime / gameTime) * 100 
    : 0;

  return (
    <div className="timer mt-4 text-center tracking-[1px] leading-[1.6]">
      <span className="block font-bold text-base sm:text-lg md:text-2xl lg:text-[2.2rem]">
        Kalan Süre: {Math.floor(timeLeft)} sn
      </span>
      <ProgressBarIkiser progress={progress} />
    </div>
  );
};

export default GameTimerIkiser;
