import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate, useLocation } from 'react-router-dom';
import { useKayanYazilarUcerOyunu } from './hooks/useKayanYazilarUcerOyunu';
import GameHeader from './components/GameHeader';
import GameDisplay from './components/GameDisplay';
import GameOverModal from './components/GameOverModal';
import GameTimerUcer from './components/GameTimer';
import SettingsModalUcer from './components/SettingsModalUcer'; 
import { API_URLS } from '../../../config/config';
import axiosInstance from '../../../utils/axiosInstance';
import './styles/kayanYazilarUcerStyle.css';

const KayanYazilarUcerOyunu = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { stage, exercise } = location.state || {};
  const token = localStorage.getItem('userToken');

  // Kullanıcı
  const [userData, setUserData] = useState(null);
  const [userRole, setUserRole] = useState(null);

  // Intro / GameOver modalları
  const [showInstructions, setShowInstructions] = useState(true);
  const [showGameOver, setShowGameOver] = useState(false);

  // (Opsiyonel) Tema - Font - FontSize (UI'dan kaldırdık, ama Modal ile açarsanız orada olabilir)
  const [theme, setTheme] = useState('default');
  const [font, setFont] = useState('Arial');
  const [fontSize, setFontSize] = useState('1rem');

  // AYARLAR MODALI
  const [showSettingsModal, setShowSettingsModal] = useState(false);
  const toggleSettingsModal = () => {
    setShowSettingsModal(!showSettingsModal);
  };

  // useKayanYazilarUcerOyunu Hook
  const {
    difficulty,
    paragraphsList,
    selectedParagraphIndex,
    displayedWords,
    isPlaying,
    speedFactor,
    handleSpeedIncrease,
    handleSpeedDecrease,
    elapsedTime,
    gameTime,
    timeLeft,
    message,
    gameOver,
    setGameOver,
    updateWords,
    handlePlayPause,
    handleReset,
    handleParagraphChange,
    handleDifficultyChange,
    difficultyLocked,
  } = useKayanYazilarUcerOyunu(navigate, userData, token, stage);

  // Kullanıcı verisi çek
  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const studentResponse = await axiosInstance.get(`${API_URLS.STUDENT_URL}/me`);
        if (
          studentResponse.status === 200 &&
          studentResponse.data &&
          studentResponse.data.status === 'active'
        ) {
          setUserData(studentResponse.data);
          setUserRole('student');
          return;
        }
      } catch (error) {
        console.error('Öğrenci verisi alınırken hata:', error);
      }

      try {
        const teacherResponse = await fetch(`${API_URLS.TEACHER_URL}/me`, {
          method: 'GET',
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        });
        if (teacherResponse.ok) {
          const teacherData = await teacherResponse.json();
          setUserData(teacherData);
          setUserRole('teacher');
          return;
        }
      } catch (error) {
        console.error('Öğretmen verisi alınırken hata:', error);
      }

      setUserData(null);
      setUserRole(null);
    };
    fetchUserData();
  }, [token]);

  // Intro -> Oyunu başlat
  const startGame = () => {
    handleReset();
    setShowInstructions(false);
  };

  // gameOver => showGameOver
  useEffect(() => {
    if (gameOver && !showGameOver) {
      setShowGameOver(true);
    }
  }, [gameOver, showGameOver]);

  // Oyun bitti => Progress
  useEffect(() => {
    if (gameOver) {
      const updateProgress = async () => {
        try {
          if (!userData || !userRole) return;
          // 3’lü => displayedWords.length * 3
          const payload = {
            stageNumber: stage?.number || 1,
            gameName: exercise?.gameName || 'Kayan Yazılar 3',
            score: displayedWords.length * 3,
            time: Math.floor(elapsedTime),
            wordCount: displayedWords.length * 3,
            completed: true,
          };

          if (userRole === 'student') {
            const apiUrl = `${API_URLS.STUDENT_URL}akillikurs/progress/update-game`;
            await axiosInstance.post(apiUrl, payload);
          } else {
            const apiUrl = `${API_URLS.TEACHER_URL}akillikurs/progress/update-game`;
            const teacherToken = localStorage.getItem('userToken');
            await axios.post(apiUrl, payload, {
              headers: {
                Authorization: `Bearer ${teacherToken}`,
                'Content-Type': 'application/json',
              },
            });
          }
        } catch (error) {
          console.error('İlerleme güncellenirken hata:', error);
        }
      };
      updateProgress();
    }
  }, [gameOver, displayedWords, elapsedTime, userData, userRole, stage, exercise]);

  // Restart
  const handleRestart = () => {
    setShowGameOver(false);
    setGameOver(false);
    handleReset();
  };

  // Geri Dön
  const handleReturnToPreviousStage = () => {
    if (stage) {
      navigate(
        userRole === 'student' ? '/ogrenci-dashboard' : '/ogretmen-dashboard',
        {
          state: {
            selectedMenu: 'ExerciseList',
            selectedStage: stage,
          },
        }
      );
    } else {
      navigate(-1);
    }
  };

  // Modal kapat
  const handleClose = () => {
    setShowGameOver(false);
    setGameOver(false);
  };

  // Tema/Font Değişimleri (sadece modaldan kontrol edilecektir)
  const handleThemeChangeLocal = (e) => setTheme(e.target.value);
  const handleFontChangeLocal = (e) => setFont(e.target.value);
  const handleFontSizeChangeLocal = (e) => setFontSize(e.target.value);

  return (
    <>
      {showInstructions ? (
      <div
      className={`
        game-container-bg
        theme-${theme}    
        min-h-screen
        flex
        flex-col
        items-center
        justify-center
        lg:justify-start
        lg:pt-10
        px-4
        sm:px-6
        md:px-8
        lg:px-12
      `}
      style={{ fontFamily: font, fontSize: '16px' }}
    >
      <div className="intro-container w-full max-w-2xl mx-auto mt-4 p-4 md:p-6  text-center">
        {/* Header => Geri dön ve Ayarlar butonu */}
        <GameHeader
          stage={stage}
          handleReturnToPreviousStage={handleReturnToPreviousStage}
          handleSettingsClick={toggleSettingsModal}
          hideSettings={true}
        />
    
        {/* Başlık */}
        <h1 className="intro-title mt-4 text-2xl font-bold mb-2">
          Kayan Yazılar 3 Çalışması
        </h1>
    
        {/* Liste (Talimatlar) */}
        <ol
          className="
            intro-instructions-list
            list-decimal 
            list-inside
            pl-5
            text-base
            space-y-2
            mb-4
          "
        >
          {[
            stage
              ? "Bu çalışmada aşamanıza göre zorluk seviyesi otomatik olarak ayarlanır."
              : "Bu çalışmada seçtiğiniz zorluk seviyesine göre metinler belirecektir.",
            "Çalışmaya başlamadan önce tüm talimatları dikkatlice okuyun.",
            "Ekranda beliren üçer kelime grubunu hızlı ve doğru şekilde takip edin.",
            "Hızınız ve doğruluğunuz skorunuzu belirleyecektir.",
            "Çalışma süresi dolduğunda, skorunuz ve ilerlemeniz otomatik olarak kaydedilecektir."
          ].map((instruction, index) => (
            <li key={index}>{instruction}</li>
          ))}
        </ol>
    
        {/* Başlat Butonu */}
        <button
          className="
            intro-button
            bg-[var(--button-background)]
            hover:bg-[var(--button-hover)]
            text-white
            font-bold
            px-5
            py-2
            rounded-md
            shadow-lg
            transition-transform
            duration-100
            active:scale-95
            mt-4
          "
          onClick={startGame}
        >
          BAŞLAT
        </button>
      </div>
    </div>
    
      
      ) : (
        <div
          className={`kayan-yazilar-ucer-game-container game-container-bg theme-${theme}`}
          style={{ fontFamily: font, fontSize }}
        >
          <div className="kayan-yazilar-ucer-gameplay">
          
            <GameHeader 
              stage={stage}
              handleReturnToPreviousStage={handleReturnToPreviousStage}
              handleSettingsClick={toggleSettingsModal} 
            />

            {/* Timer */}
            <GameTimerUcer
              timeLeft={timeLeft}
              isPlaying={isPlaying}
              gameTime={gameTime}
              elapsedTime={elapsedTime}
            />

            {/* GameDisplay => HIZ AYARI BURADA */}
            <GameDisplay
              isPlaying={isPlaying}
              paragraphsList={paragraphsList}
              selectedParagraphIndex={selectedParagraphIndex}
              displayedWords={displayedWords}
              updateWords={updateWords}
              handlePlayPause={handlePlayPause}
              handleReset={handleReset}
              message={message}
              gameOver={gameOver}

              // HIZ AYARI:
              speedFactor={speedFactor}
              handleSpeedIncrease={handleSpeedIncrease}
              handleSpeedDecrease={handleSpeedDecrease}
            />
          </div>
        </div>
      )}

      {/* Game Over */}
      {showGameOver && (
        <GameOverModal
          score={displayedWords.length * 3}
          elapsedTime={Math.floor(elapsedTime)}
          onRestart={handleRestart}
          onClose={handleClose}
          userData={userData}
        />
      )}

      {/* AYARLAR MODALI */}
      {showSettingsModal && (
        <SettingsModalUcer
          onClose={toggleSettingsModal}
          difficulty={difficulty}
          difficultyLocked={difficultyLocked}
          paragraphsList={paragraphsList}
          selectedParagraphIndex={selectedParagraphIndex}
          handleDifficultyChange={handleDifficultyChange}
          handleParagraphChange={handleParagraphChange}
          theme={theme}
          handleThemeChange={handleThemeChangeLocal}
          font={font}
          handleFontChange={handleFontChangeLocal}
          fontSize={fontSize}
          handleFontSizeChange={handleFontSizeChangeLocal}
        />
      )}
    </>
  );
};

export default KayanYazilarUcerOyunu;
