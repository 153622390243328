import React from 'react';
import '../styles/kayanYazilarStyle.css';

const GameTimer = ({ timeLeft, isPlaying }) => {
  const formatTime = (milliseconds) => {
    let seconds = Math.ceil(milliseconds / 1000);
    if (seconds < 0) seconds = 0;

    const minutes = Math.floor(seconds / 60)
      .toString()
      .padStart(2, '0');
    const remainingSeconds = (seconds % 60)
      .toString()
      .padStart(2, '0');

    return `${minutes}:${remainingSeconds}`;
  };

  return (
    <div className="timer mt-4 text-center tracking-[1px] leading-[1.6]">
      <span className="block font-bold text-base sm:text-lg md:text-2xl lg:text-[2.2rem]">
        Kalan Süre: {formatTime(timeLeft)}
      </span>
    </div>
  );
};

export default GameTimer;
