import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlay } from '@fortawesome/free-solid-svg-icons';
import Header from '../components/Header';

const Instructions = ({ startGame, title, description, handleReturnToPreviousStage }) => {
  return (
    <div
      className="
        intro-container
        w-full
        max-w-2xl
        mx-auto
        mt-4
        p-4
        md:p-6
        text-center
      "
    >
      <Header handleReturnToPreviousStage={handleReturnToPreviousStage} />

      {/* Başlık ve açıklama */}
      <h1 className="intro-title mt-4 text-2xl font-bold mb-2">
        {title}
      </h1>
      <p className="intro-description text-base mb-4">
        {description}
      </p>

      {/* Talimatlar kutusu */}
      <div
        className="
          intro-instructions-box
          bg-white
          p-6
          rounded-md
          shadow
          w-full
          max-w-xl
          mb-4
        "
      >
        <h3
          className="
            intro-instructions-title
            text-lg
            font-semibold
            mb-3
          "
        >
          Talimatlar
        </h3>
        <ul
          className="
            intro-instructions-list
            list-none
            text-sm
            text-gray-700
            space-y-2
          "
        >
          <li>1. Zorluk Seviyesi Seçin: Kolay, Orta veya Zor</li>
          <li>2. Çalışmayı Başlatın: "Başlat" butonuna tıklayın.</li>
          <li>3. Cümleleri İzleyin: Alt kısımdan cümleler gelecektir.</li>
        </ul>
      </div>

      <button
        className="
          intro-button
          bg-blue-600
          hover:bg-blue-700
          text-white
          font-bold
          px-5
          py-2
          rounded-md
          shadow-lg
          transition-transform
          duration-100
          active:scale-95
          mt-4
        "
        onClick={startGame}
      >
        <FontAwesomeIcon icon={faPlay} /> BAŞLAT
      </button>
    </div>
  );
};

export default Instructions;
