import React from 'react';
import DOMPurify from 'dompurify';
import parse, { domToReact } from 'html-react-parser';
import '../styles/KelimeBulmaOyunu.css';
import TimerDisplay from './TimerDisplay';
import GameControls from './GameControls';

const GameBoard = ({
  paragraph,
  selectedWords,
  targetWords,
  handleWordClick,
  correctWords,
  timeRemaining,
  isPlaying,
  handlePlayPause,
  handleReset
}) => {
  // Boş element listesi
  const voidElements = new Set([
    'area',
    'base',
    'br',
    'col',
    'embed',
    'hr',
    'img',
    'input',
    'keygen',
    'link',
    'meta',
    'param',
    'source',
    'track',
    'wbr',
  ]);

  let wordCounter = 0;

  const options = {
    replace: (domNode) => {
      // Eğer etiket void type ise (br, img vs.), çocukları olmaz
      if (domNode.type === 'tag') {
        if (voidElements.has(domNode.name)) {
          return React.createElement(
            domNode.name,
            { key: domNode.name + wordCounter, ...domNode.attribs },
            null
          );
        } else {
          return React.createElement(
            domNode.name,
            { key: domNode.name + wordCounter, ...domNode.attribs },
            domToReact(domNode.children, options)
          );
        }
      } 
      // Metin nodeları
      else if (domNode.type === 'text') {
        const textContent = domNode.data;
        const words = textContent.split(/(\s+)/); // Boşlukları böl

        const newNodes = [];
        words.forEach((word) => {
          if (word.trim() === '') {
            // Boşluk ise olduğu gibi ekle
            newNodes.push(word);
          } else {
            // Asıl kelime - normalize
            const originalWord = word;
            const normalizedWord = word
              .toLowerCase()
              .replace(/[^\p{L}\p{N}_]+/gu, '');

            const isSelected = selectedWords.includes(normalizedWord);
            const isCorrect = targetWords.includes(normalizedWord);

            const className = `kbo-word ${
              isSelected ? (isCorrect ? 'correct' : 'incorrect') : ''
            }`;

            newNodes.push(
              <span
                key={`word-${wordCounter}`}
                className={className}
                onClick={() => handleWordClick(normalizedWord)}
              >
                {originalWord}
              </span>
            );
            wordCounter++;
          }
        });

        return <>{newNodes}</>;
      }
    },
  };

  const sanitizedHTML = DOMPurify.sanitize(paragraph);

  return (
    <div
      className="
        
        w-full
        max-w-7xl           
        mx-auto
        px-4
        py-4
        md:px-6
        md:py-6
        flex
        flex-col
        items-center
        bg-white            
        shadow              
        rounded-md
      "
    >
      {/* Oyun Kontrolleri */}
      <GameControls
        isPlaying={isPlaying}
        handlePlayPause={handlePlayPause}
        handleReset={handleReset}
      />

      {/* Zamanlayıcı */}
      <TimerDisplay timeRemaining={timeRemaining} />

      {/* Hedef Kelimeler Alanı */}
      <div
  className="
    mt-4
    flex
    flex-wrap
    items-center
    justify-center
    gap-2
    text-sm       /* Varsayılan (küçük ekranlar) */
    sm:text-base  /* Küçükten büyük ekrana doğru */
    md:text-lg
    lg:text-xl
    xl:text-2xl
  "
>
  {targetWords.map((word, index) => {
    const isFound = correctWords.includes(word);
    return (
      <span
        key={index}
        className={`kbo-target-word ${isFound ? 'found' : ''}`}
      >
        {word}
      </span>
    );
  })}
</div>


      {/* Paragraf (Oyun) İçeriği */}
      <div
  className="
    w-full
    mt-6
    p-4
    rounded
    overflow-auto
    flex-1
    bg-gray-50
    text-sm       /* Varsayılan (küçük ekranlar) */
    sm:text-base  /* 640px ve üzeri */
    md:text-lg
    lg:text-xl
    xl:text-2xl
  "
>
  {paragraph ? (
    <div>{parse(sanitizedHTML, options)}</div>
  ) : (
    <div className="kbo-message-display text-center text-red-600">
      Seçili paragrafta içerik bulunamadı. Lütfen farklı bir paragraf seçin.
    </div>
  )}
</div>

    </div>
  );
};

export default GameBoard;
