import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate, useLocation } from 'react-router-dom';
import { useLetterGridGame } from './hooks/useLetterGridGame';
import HeaderSection from './components/HeaderSection';
import GameControls from './components/GameControls';
import GameStatus from './components/GameStatus';
import LetterGrid from './components/LetterGrid';
import MessageAlert from './components/MessageAlert';
import GameOverModal from './components/GameOverModal';
import { API_URLS } from '../../../config/config';
import axiosInstance from '../../../utils/axiosInstance';
import SettingsModal from './components/SettingsModal';
import './styles/harfIzgarasiStyle.css';

const HarfIzgarasiOyunu = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { stage, exercise } = location.state || {};
  const token = localStorage.getItem('userToken');

  const [userData, setUserData] = useState(null);
  const [userRole, setUserRole] = useState(null);

  // Ayarlar için modal state ve fonksiyonları
  const [isSettingsOpen, setIsSettingsOpen] = useState(false);
  const openSettings = () => setIsSettingsOpen(true);
  const closeSettings = () => setIsSettingsOpen(false);

  // Aşamaya göre otomatik zorluk kilidi
  const stageNumber = stage?.number || null;
  let initialDifficulty = 'easy';
  let difficultyLocked = false;

  if (stageNumber) {
    difficultyLocked = true;
    if (stageNumber >= 1 && stageNumber <= 7) {
      initialDifficulty = 'easy';
    } else if (stageNumber >= 8 && stageNumber <= 14) {
      initialDifficulty = 'medium';
    } else {
      initialDifficulty = 'hard';
    }
  }

  // Kullanıcı verileri çekme
  useEffect(() => {
    const fetchUserData = async () => {
      try {
        // Öğrenci verisi
        const studentResponse = await axiosInstance.get(`${API_URLS.STUDENT_URL}/me`);
        if (
          studentResponse.status === 200 &&
          studentResponse.data &&
          studentResponse.data.status === 'active'
        ) {
          setUserData(studentResponse.data);
          setUserRole('student');
          return;
        } else {
          setUserData(null);
          setUserRole(null);
        }
      } catch (error) {
        console.error('Öğrenci verisi alınırken hata oluştu:', error);
      }

      // Öğrenci yoksa veya aktif değilse öğretmene bak
      try {
        const teacherResponse = await fetch(`${API_URLS.TEACHER_URL}/me`, {
          method: 'GET',
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        });
        if (teacherResponse.ok) {
          const teacherData = await teacherResponse.json();
          setUserData(teacherData);
          setUserRole('teacher');
          return;
        }
      } catch (error) {
        console.error('Öğretmen verisi alınırken hata oluştu:', error);
      }

      setUserData(null);
      setUserRole(null);
    };

    fetchUserData();
  }, [token]);

  const [finalScore, setFinalScore] = useState(0);
  const [finalCorrectSelections, setFinalCorrectSelections] = useState(0);
  const [finalIncorrectSelections, setFinalIncorrectSelections] = useState(0);

  // Oyun bittiğinde modal açıp skorları set et
  const [showGameOver, setShowGameOver] = useState(false);
  const onGameOver = (score, correctSelections, incorrectSelections) => {
    setShowGameOver(true);
    setFinalScore(score);
    setFinalCorrectSelections(correctSelections);
    setFinalIncorrectSelections(incorrectSelections);
    updateProgress(score);
  };

  // useLetterGridGame hook
  const {
    letters,
    targetLetters,
    message,
    timeRemaining,
    score,
    isPlaying,
    clickedLetters,
    difficulty,
    gridSize,
    handlePlayPause,
    handleReset,
    handleLetterClick,
    handleDifficultyChange: originalHandleDifficultyChange,
  } = useLetterGridGame(userData, onGameOver, initialDifficulty);

  // Giriş Ekranı
  const [showInstructions, setShowInstructions] = useState(true);

  // Tema / Font / Boyut ayarları
  const [theme, setTheme] = useState('default');
  const handleThemeChange = (e) => {
    setTheme(e.target.value);
  };
  const [font, setFont] = useState('Arial');
  const handleFontChange = (e) => {
    setFont(e.target.value);
  };
  const [fontSize, setFontSize] = useState('1rem');
  const handleFontSizeChange = (e) => {
    setFontSize(e.target.value);
  };

  // İlerleme güncelleme
  const updateProgress = async (score) => {
    if (!userData || !userRole) return;
    try {
      const payload = {
        stageNumber: stage?.number || 1,
        gameName: exercise?.gameName || 'Harf Izgara',
        score: score,
        time:
          (userData && userData.status === 'inactive' ? 15 : 60) - timeRemaining,
        completed: true,
      };

      if (userRole === 'student') {
        // Öğrencinin ilerlemesi
        const apiUrl = `${API_URLS.STUDENT_URL}akillikurs/progress/update-game`;
        await axiosInstance.post(apiUrl, payload);
      } else {
        // Öğretmenin ilerlemesi
        const apiUrl = `${API_URLS.TEACHER_URL}akillikurs/progress/update-game`;
        const teacherToken = localStorage.getItem('userToken');
        await axios.post(apiUrl, payload, {
          headers: {
            Authorization: `Bearer ${teacherToken}`,
            'Content-Type': 'application/json',
          },
        });
      }
    } catch (error) {
      console.error('İlerleme güncellenirken hata oluştu:', error);
    }
  };

  // Oyunu başlatmak için Intro'yu kapat
  const startGame = () => {
    setShowInstructions(false);
  };

  const handleRestart = () => {
    setShowGameOver(false);
    handleReset();
  };

  // Bir önceki aşama sayfasına / dashboard'a dön
  const handleReturnToPreviousStage = () => {
    if (stage) {
      navigate(
        userRole === 'student' ? '/ogrenci-dashboard' : '/ogretmen-dashboard',
        {
          state: {
            selectedMenu: 'ExerciseList',
            selectedStage: stage,
          },
        }
      );
    } else {
      alert('Aşama bilgisi bulunmamaktadır.');
    }
  };

  const handleCloseModal = () => {
    setShowGameOver(false);
  };

  const handleDifficultyChange = (e) => {
    if (difficultyLocked) return;
    originalHandleDifficultyChange(e);
  };

  return (
    <>
      {showInstructions ? (
     <div
     className={`   
       harf-izgarasi-container
       game-container-bg
       theme-${theme}
       bg-[var(--background-color)]
       text-[var(--text-color)]
       min-h-screen
       flex
       flex-col
       items-center
       justify-center
       lg:justify-start
       lg:pt-10
       px-4 
       sm:px-6 
       md:px-8 
       lg:px-12
     `}
     style={{ fontFamily: font, fontSize }}
   >
   
     <div 
       className="
         intro-container
         w-full
         max-w-2xl
         mx-auto
         p-4
         md:p-6
       "
     >
   
       <HeaderSection
         stage={stage}
         handleReturnToPreviousStage={handleReturnToPreviousStage}
         openSettings={openSettings}
         hideSettings={true}
       />
   
    
       <h1 className="intro-title mt-4  text-center text-2xl font-bold mb-2">
         Harf Izgarası Çalışması
       </h1>
   
    
       {stage ? (
         <p className="intro-description text-center text-base mb-4 max-w-md mx-auto">
           Bu çalışmada aşamanıza göre zorluk seviyesi otomatik belirlenecek.
           Verilen süre içinde hedef harfleri ızgarada bulmaya çalışın.
         </p>
       ) : (
         <p className="intro-description text-center text-base mb-4 max-w-md mx-auto">
           Bu çalışma amacı, verilen süre içerisinde hedef harfleri ızgarada bulmaktır.
         </p>
       )}
   
   
       <div
         className="
           intro-instructions-box
           bg-white
           p-6
           rounded-md
           shadow
           w-full
           max-w-xl
           mb-4
         "
       >
         <h3
           className="
             intro-instructions-title
             text-lg
             font-semibold
             mb-3
             text-[#211C84]
           "
         >
           Talimatlar
         </h3> 
         <ul
           className="
             intro-instructions-list
             list-none
             text-sm
             text-gray-700
             mb-4
             space-y-2
           "
         >
           <li>1. Verilen hedef harfleri ızgarada bulup tıklayın.</li>
           <li>2. Her doğru seçim için puan kazanırsınız.</li>
           <li>3. Süre dolduğunda çalışma sona erer.</li>
         </ul>
       </div>
   
     
       <button
         className="
           intro-button
           bg-[var(--button-background)]
           hover:bg-[var(--button-hover)]
           text-white
           font-bold
           px-5
           py-2
           rounded-md
           shadow-lg
           transition-transform
           duration-100
           active:scale-95
           mx-auto
           block
         "
         onClick={startGame}
       >
         BAŞLAT
       </button>
     </div>
   </div>
   
       
      ) : (
        /* =======================
           OYUN EKRANI
           ======================= */
        <div
          className={`
            harf-izgarasi-container
            game-container-bg
            theme-${theme}
            bg-[var(--background-color)] 
            min-h-screen 
            text-[var(--text-color)]
            p-5
            flex 
            flex-col
          `}
          style={{ fontFamily: font, fontSize }}
        >
          {/* Üst kısım: Header / Ayarlar / Geri dönüş */}
          <HeaderSection
            stage={stage}
            handleReturnToPreviousStage={handleReturnToPreviousStage}
            openSettings={openSettings}
          />

          {/* Oyun Kontrolleri */}
          <div className="harf-izgarasi-gameplay flex flex-col items-center">
            <GameControls
              isPlaying={isPlaying}
              handlePlayPause={handlePlayPause}
              handleReset={handleReset}
            />

            {/* Settings Modal */}
            <SettingsModal
              isOpen={isSettingsOpen}
              onClose={closeSettings}
              difficulty={difficulty}
              handleDifficultyChange={handleDifficultyChange}
              difficultyLocked={difficultyLocked}
              theme={theme}
              handleThemeChange={handleThemeChange}
              font={font}
              handleFontChange={handleFontChange}
              fontSize={fontSize}
              handleFontSizeChange={handleFontSizeChange}
            />

            {/* Oyun Durumu */}
            <GameStatus timeRemaining={timeRemaining} score={score} />

            {/* Izgara */}
            <LetterGrid
              letters={letters}
              targetLetters={targetLetters}
              clickedLetters={clickedLetters}
              handleLetterClick={handleLetterClick}
              gridSize={gridSize}
            />

            {/* Geribildirim Mesajı */}
            {message && (
              <MessageAlert
                message={message}
                // Mesaj animasyonu (fadeInOut) => 
                // harf-izgarasi-message sınıfıyla yakalayabilirsiniz,
                // veya Tailwind arbitary animation ekleyebilirsiniz.
                className="
                  harf-izgarasi-message 
                  text-xl 
                  font-bold 
                  mt-4
                  text-[var(--title-color)] 
                "
              />
            )}
          </div>

          {/* Oyun Bitti Modal */}
          {showGameOver && (
            <GameOverModal
              score={finalScore}
              correctCount={finalCorrectSelections}
              incorrectCount={finalIncorrectSelections}
              onRestart={handleRestart}
              userData={userData}
              onClose={handleCloseModal}
            />
          )}
        </div>
      )}
    </>
  );
};

export default HarfIzgarasiOyunu;
