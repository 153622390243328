import React from 'react';

const GameExplanation = () => {
  return (
    <div
      className="
        intro-instructions-box
        bg-white
        p-6
        rounded-md
        shadow
        w-full
        max-w-xl
        mb-4
      "
    >
      <h3
        className="
          intro-instructions-title
          text-lg
          font-semibold
          mb-3
          text-[#211C84]
        "
      >
        Talimatlar
      </h3>
      <ul
        className="
          intro-instructions-list
          list-none
          text-sm
          text-gray-700
          space-y-2
        "
      >
        <li>1. Zorluk seviyesini ve okumak istediğiniz paragrafı seçin.</li>
        <li>2. "Başla" butonuna tıklayarak çalışmayı başlatın.</li>
        <li>3. Hedef kelimeleri paragraf içinde bulun ve üzerine tıklayın.</li>
        <li>4. Doğru kelimeleri buldukça puan kazanın.</li>
      </ul>
    </div>
  );
};

export default GameExplanation;
