import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate, useLocation } from 'react-router-dom';
import { useRasgeleKelimelerOyunu } from './hooks/useRasgeleKelimelerOyunu';
import HeaderSection from './components/HeaderSectionRandomWords';
import GameDisplay from './components/GameDisplayRandomWords';
import GameOverModal from './components/GameOverModalRandomWords';
import { API_URLS } from '../../../config/config';
import './styles/rasgeleKelimelerStyle.css';
import axiosInstance from '../../../utils/axiosInstance';

// *** Modal Bileşenini import ediyoruz:
import SettingsModalRandomWords from './components/SettingsModalRandomWords';

const RasgeleKelimelerOyunu = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { stage, exercise } = location.state || {};
  const token = localStorage.getItem('userToken');

  const [userData, setUserData] = useState(null);
  const [userRole, setUserRole] = useState(null);

  const [showInstructions, setShowInstructions] = useState(true);
  const [showGameOver, setShowGameOver] = useState(false);

  // Tema, font, fontSize
  const [theme, setTheme] = useState('default');
  const [fontSize, setFontSize] = useState('1.6rem');
  const [font, setFont] = useState('Arial'); 

  // Modal aç/kapa için state
  const [showSettingsModal, setShowSettingsModal] = useState(false);

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const studentResponse = await axiosInstance.get(`${API_URLS.STUDENT_URL}/me`);
        if (
          studentResponse.status === 200 &&
          studentResponse.data &&
          studentResponse.data.status === 'active'
        ) {
          setUserData(studentResponse.data);
          setUserRole('student');
          return;
        } else {
          setUserData(null);
          setUserRole(null);
        }
      } catch (error) {
        console.error('Öğrenci verisi alınırken hata oluştu:', error);
      }

      try {
        const teacherResponse = await fetch(`${API_URLS.TEACHER_URL}/me`, {
          method: 'GET',
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        });
        if (teacherResponse.ok) {
          const teacherData = await teacherResponse.json();
          setUserData(teacherData);
          setUserRole('teacher');
          return;
        }
      } catch (error) {
        console.error('Öğretmen verisi alınırken hata oluştu:', error);
      }

      setUserData(null);
      setUserRole(null);
    };

    fetchUserData();
  }, [token]);


  const {
    difficulty,
    paragraphsList,
    selectedParagraphIndex,
    speedFactor,
    isPlaying,
    elapsedTime,
    timeLeft,
    message,
    gameOver,
    setGameOver: setGameOverFromHook,
    displayedWordsGroup,
    allWords,
    currentWordIndex,
    handlePlayPause,
    handleReset,
    handleSpeedIncrease,
    handleSpeedDecrease,
    handleParagraphChange,
    handleDifficultyChange,
  } = useRasgeleKelimelerOyunu(navigate, userData, token);

  // Talimat (intro) ekranı
  const startGame = () => {
    handleReset();
    setShowInstructions(false);
  };

  // Game Over
  useEffect(() => {
    if (gameOver && !showGameOver) {
      setShowGameOver(true);
    }
  }, [gameOver, showGameOver]);

  // Oyun bittiğinde ilerleme kaydı
  useEffect(() => {
    if (gameOver) {
      const updateProgress = async () => {
        try {
          if (userData && userRole) {
            const totalWords = allWords.length;
            const payload = {
              stageNumber: stage?.number || 1,
              gameName: exercise?.gameName || 'Rasgele Kelimeler',
              score: totalWords,
              time: Math.floor(elapsedTime / 1000),
              wordCount: totalWords,
              completed: true,
            };

            let apiUrl;
            if (userRole === 'student') {
              apiUrl = `${API_URLS.STUDENT_URL}akillikurs/progress/update-game`;
              await axiosInstance.post(apiUrl, payload);
            } else {
              apiUrl = `${API_URLS.TEACHER_URL}akillikurs/progress/update-game`;
              const teacherToken = localStorage.getItem('userToken');
              await axios.post(apiUrl, payload, {
                headers: {
                  Authorization: `Bearer ${teacherToken}`,
                  'Content-Type': 'application/json',
                },
              });
            }
          }
        } catch (error) {
          console.error('İlerleme güncellenirken hata oluştu:', error);
        }
      };
      updateProgress();
    }
  }, [gameOver, allWords, elapsedTime, userData, userRole, stage, exercise, token]);

  const handleRestart = () => {
    setShowGameOver(false);
    setGameOverFromHook(false);
    handleReset();
  };

  const handleReturnToPreviousStage = () => {
    if (stage) {
      navigate(
        userRole === 'student' ? '/ogrenci-dashboard' : '/ogretmen-dashboard',
        {
          state: {
            selectedMenu: 'ExerciseList',
            selectedStage: stage,
          },
        }
      );
    } else {
      alert('Aşama bilgisi bulunmamaktadır.');
    }
  };

  const handleCloseGameOverModal = () => {
    setShowGameOver(false);
    setGameOverFromHook(false);
  };

  return (
    <>
      {showInstructions ? (
       <div
       className={`
         game-container-bg
         rasgele-oyun-theme-${theme}
     
         min-h-screen
         flex
         flex-col
         items-center
         justify-center
     
         lg:justify-start
         lg:pt-10
     
         px-4
         sm:px-6
         md:px-8
         lg:px-12
       `}
       style={{ fontFamily: font, fontSize: '16px' }}
     >
       <div className="intro-container w-full max-w-2xl mx-auto p-4 md:p-6  text-center">
         <HeaderSection
           stage={stage}
           handleReturnToPreviousStage={handleReturnToPreviousStage}
           setShowSettingsModal={setShowSettingsModal}
           hideSettings={true}
         />
     
         <h1 className="intro-title mt-4 text-2xl font-bold mb-2">
           Rasgele Kelimeler
         </h1>
         <p className="intro-description text-base mb-4">
           Bu çalışmada ekrana rastgele konumlarda kelime veya ikili kelime grupları
           belirecek. Amacınız bu kelimeleri en hızlı şekilde okumak.
         </p>
     
         <div
           className="
             intro-instructions-box
             bg-white
             p-6
             rounded-md
             shadow
             w-full
             max-w-xl
             mb-4
           "
         >
           <h3
             className="
               intro-instructions-title
               text-lg
               font-semibold
               mb-3
               text-[#211C84]
             "
           >
             Talimatlar
           </h3>
           <ul
             className="
               intro-instructions-list
               list-none
               text-sm
               text-gray-700
               space-y-2
             "
           >
             <li>1. Zorluk seviyesini ve okumak istediğiniz paragrafı seçin.</li>
             <li>2. "Başlat" butonuna tıklayarak çalışmayı başlatın.</li>
             <li>3. Ekrada beliren kelime veya kelime çiftlerini takip edip okuyun.</li>
           </ul>
         </div>
     
         <button
           className="
             intro-button
             bg-[var(--button-background)]
             hover:bg-[var(--button-hover)]
             text-white
             font-bold
             px-5
             py-2
             rounded-md
             shadow-lg
             transition-transform
             duration-100
             active:scale-95
             mt-2
           "
           onClick={startGame}
         >
           BAŞLAT
         </button>
       </div>
     </div>
     
      ) : (
        <div
          className={`rasgele-kelimeler-game-container game-container-bg rasgele-oyun-theme-${theme}`}
          style={{ fontFamily: font, fontSize }}
        >
          <div className="rasgele-kelimeler-gameplay">
          <HeaderSection 
            stage={stage}
            handleReturnToPreviousStage={handleReturnToPreviousStage} 
            setShowSettingsModal={setShowSettingsModal} 
          />
        

            <GameDisplay
              isPlaying={isPlaying}
              paragraphsList={paragraphsList}
              selectedParagraphIndex={selectedParagraphIndex}
              displayedWordsGroup={displayedWordsGroup}
              handlePlayPause={handlePlayPause}
              handleReset={handleReset}
              message={message}
              gameOver={gameOver}
              fontSize={fontSize}
              currentWordIndex={currentWordIndex}
              timeLeft={timeLeft}
              handleSpeedDecrease={handleSpeedDecrease}
              speedFactor={speedFactor.toFixed(1)}
              handleSpeedIncrease={handleSpeedIncrease}

            />

          
          </div>
        </div>
      )}

      {/* Ayarlar Modalı */}
      {showSettingsModal && (
        <SettingsModalRandomWords
          onClose={() => setShowSettingsModal(false)}
          difficulty={difficulty}
          handleDifficultyChange={handleDifficultyChange}
          paragraphsList={paragraphsList}
          selectedParagraphIndex={selectedParagraphIndex}
          handleParagraphChange={handleParagraphChange}
          theme={theme}
          font={font}
          fontSize={fontSize}
          setTheme={setTheme}
          setFont={setFont}
          setFontSize={setFontSize}
        />
      )}

      {/* Oyun Bitti Modalı */}
      {showGameOver && (
        <GameOverModal
          score={allWords.length}
          elapsedTime={Math.floor(elapsedTime / 1000)}
          onRestart={handleRestart}
          onClose={handleCloseGameOverModal}
          userData={userData}
        />
      )}
    </>
  );
};

export default RasgeleKelimelerOyunu;
